import {MenuItemQuantity} from "../CartScreen/CartScreen.styled";
import {NinaOrderItemI, NinaOrderItemVariationI} from "../../cartInterfaces";
import React from "react";

interface OrderStatusScreenItemI {
    item: NinaOrderItemI
}
export function OrderStatusScreenItem({item}: OrderStatusScreenItemI) {
    const calcItemPrice = (): string => {
        let price: number = parseFloat(item.Price) * parseInt(item.Quantity);
        item.nameWithVariation.forEach(variation => {
            price += parseFloat(variation.price ?? 0) * variation.Quantity * parseInt(item.Quantity);
        })
        return price.toFixed(2).toString();
    }
    return (
        <div className="mb-4">
            <div className="flex place-content-between items-center content-center">
                <div className="flex items-center">

                    <span className="inline-block pr-2 fore-color">
                        {item.ItemName}
                    </span>
                    <MenuItemQuantity>{item.Quantity}</MenuItemQuantity>
                </div>
                <div>
                    <span className="text-lg ml-2" style={{
                        color: "var(--theme-primary-color)"
                    }}>&euro;{calcItemPrice()}</span>
                </div>
            </div>
            <ul className="list-disc ml-8">
                {
                    item.nameWithVariation.map(variation => {
                        return <OrderStatusScreenItemVariation key={variation.OptionID} {...variation} />
                    })
                }
            </ul>
            {item.Notes &&
                <div className="indent mt-1 text-lg">
                    <p className="" style={{color: "var(--theme-primary-color)"}}>Notes:</p>
                    <p>{item.Notes}</p>
                </div>
            }
            <hr className="my-3"/>
        </div>
    )
    function getPrice(){
        return calcItemPrice();
    }
}

function OrderStatusScreenItemVariation(variation: NinaOrderItemVariationI) {
    return (
        <li style={{color: "grey"}} key={variation.OptionID} className="">
            <p className="leading-3">
                {variation.SelectionName}
                {variation.Quantity > 1 &&
                    <span> (x{variation.Quantity})</span>
                }
            </p>
        </li>
    )
}
