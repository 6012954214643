import {Translate} from "../../../intl/translator";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectUser, setUserFormData} from "../../userSlice";
import {SubmitHandler, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
// import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import {userSendOtp} from "../../userRepository";
import {UserI} from "../../userInterfaces";
import {OtpForm} from "../OtpForm/OtpForm";
import * as StyledTheme from "../../../theme/styled-components/Theme.styled";
import {VendorLogo} from "../../../vendor/components/VendorLogo/VendorLogo";
import {VendorLogoPosition} from "../../../vendor/components/VendorLogo/VendorLogo.styled";
import {FormButton} from "../../../theme/styled-components/Form.styled";
import {Footer} from "../../../theme/copmponents/Footer";
import {AppInputField} from "../../../theme/styled-components/Form.styled";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {BsChevronLeft} from "react-icons/bs";
import {useNavigate} from "react-router-dom";

interface Inputs {
    name: string,
    email: string,
    phone: string,
    lastname:string
}

const RegisterScreen = () => {
    const strings = {
        s_title: Translate('register_title'),
        s_description: Translate('register_description'),
        s_button: Translate('get_otp_button'),
        s_name_label: Translate('first_name'),
        s_sname_label: Translate('last_name'),
        
        s_email_label: Translate('email_address_from_field'),
        s_phone_label: Translate('mobile_number'),
        s_name_validation: Translate('name_validation'),
        s_email_validation: Translate('email_validation'),
    }
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const {register, handleSubmit, formState: {errors}} = useForm<Inputs>();
    const [otpOpen, setOtpOpen] = useState(false);
    const [otpTimeout, setOtpTimeout] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        let otpInterval = setInterval(() => {
            if(otpTimeout > 0) {
                setOtpTimeout(otpTimeout - 1);
            }
        }, 1000)
        return () => {clearInterval(otpInterval)}
    })

    const handleNameValidation = (name: string) => {
        let isValid = true;
        let parts = name.split(' ');
        if(parts.length < 2){
            return false;
        }
        parts.forEach(part => {
            if(part.length < 3){
                isValid = false;
            }
        })
        return isValid;
    }

    const onSubmit: SubmitHandler<Inputs> = data => {
        if(otpTimeout > 0) return;
        setOtpTimeout(60)
        let f_name = encodeURIComponent(data.name);
        let l_name = encodeURIComponent(data.lastname);
        // let pn = parsePhoneNumber(data.phone);
        let userForm: UserI = {
            RegisteredUserID: user.RegisteredUserID,
            Verified: user.Verified,
            Name: f_name,
            Surname: l_name,
            Email: data.email,
            PhoneNum: user.PhoneNum,
            PhoneCode: user.PhoneCode,
            // PhoneNum: pn?.nationalNumber,
            // PhoneCode: "+" + pn?.countryCallingCode,
            Addresses: []
        }
        if(userForm.PhoneNum && userForm.PhoneCode) {
            dispatch(setUserFormData(userForm));
            userSendOtp(userForm.PhoneNum, userForm.PhoneCode).then(() => {
                setOtpOpen(true);

            })
        }

    };

    const closeOtp = () => {
        setOtpOpen(false);
        navigate("/login");
    }

    return (
        <div className="bg-with-gradient flex flex-col">
            <StyledTheme.SizedContentPage >
                <div className="actions indent  py-8">
                    <div className="flex items-center">
                        <div className="flex items-center cursor-pointer" onClick={closeOtp}>
                            <ActionButton>
                                <BsChevronLeft size={24}/>
                            </ActionButton>
                            <div className="pl-8">Change Number</div>
                        </div>
                    </div>
                </div>
            </StyledTheme.SizedContentPage>
            <Footer>
                <div>
                    <div>
                        <VendorLogo placement={VendorLogoPosition.Card} />
                    </div>
                    <div className='card-panel'>
                        <div style={{background: "white"}} className="pt-36 px-8 pb-12 md:px-20">
                            {!otpOpen &&
                                <>
                                    <h1 className='text-3xl'>{strings.s_title}</h1>
                                    <p className='mt-6 mb-8 md:mb-8'>
                                        {strings.s_description}
                                    </p>
                                    <form onSubmit={handleSubmit(onSubmit)} className="form indent mt-8">
                                        <p>{strings.s_name_label}</p>
                                        <AppInputField
                                            className={'small mb-8'}
                                            defaultValue={(user.Name ?? '')}
                                            {...register("name", {
                                                           
                                            })}
                                                       />
                                        {errors.name && <span className="text-red-600">{strings.s_name_validation}</span>}
                                        <p>{strings.s_sname_label}</p>
                                        <AppInputField 
                                            className={'small mb-8'}
                                            defaultValue={user.Surname ?? ''}
                                                       {...register("lastname", {
                                                           
                                                       })}/>
                                        {errors.name && <span className="text-red-600">{strings.s_name_validation}</span>}
                                        <p className="mt-4">{strings.s_phone_label}</p>
                                        <p className="mt-2 text-gray-500">{user.PhoneCode} {user.PhoneNum}</p>
                                        {/*<Controller
                                            control={control}
                                            render={({field: {onChange, value}}) => {
                                                return <PhoneInput
                                                    className={'small'}
                                                    international={false}
                                                    focusInputOnCountrySelection
                                                    placeholder="Enter phone number"
                                                    defaultCountry="CY"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            }
                                            }
                                            name="phone"
                                            rules={{required: true}}
                                        />
                                        {errors.phone && <span className="text-red-600">Please enter a valid phone number.</span>}*/}

                                        <p className="mt-4">{strings.s_email_label}</p>
                                        <AppInputField
                                            className={'small mb-8'}
                                            defaultValue={user.Email}
                                            {
                                                ...register("email", {
                                                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                })
                                            }
                                        />
                                        {errors.email && <span className="text-red-600">{strings.s_email_validation}</span>}
                                        <FormButton className={'mt-8'} onClick={handleSubmit(onSubmit)}>
                                            {strings.s_button}
                                            {otpTimeout > 0 && ' ('+otpTimeout+')'}
                                        </FormButton>
                                    </form>
                                </>
                            }
                            <OtpForm open={otpOpen} setOpen={setOtpOpen} otpTimeout={otpTimeout}
                                     setOtpTimeout={setOtpTimeout}/>
                        </div>
                    </div>
                </div>

            </Footer>
        </div>
    )
}

export default RegisterScreen;
