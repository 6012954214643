import * as StyledTheme from "../../../theme/styled-components/Theme.styled";
import {ActionButton} from "../../../theme/styled-components/UI.styled";
import {BsChevronLeft} from "react-icons/bs";
import {Footer} from "../../../theme/copmponents/Footer";
import {VendorLogo} from "../../../vendor/components/VendorLogo/VendorLogo";
import {VendorLogoPosition} from "../../../vendor/components/VendorLogo/VendorLogo.styled";
import React, {useEffect} from "react";
import * as styled from "./../AddAddress/AddAdress.styled";
import {useNavigate} from "react-router-dom";
import {AppInputField} from "../../../theme/styled-components/Form.styled";
import {FormButton} from "../../../theme/styled-components/Form.styled";
import {SubmitHandler, useForm} from "react-hook-form";
import {UserAddressI} from "../../userInterfaces";
import CONFIG from "../../../../app/config";
import {userEditAddress} from "../../userRepository";
import {useAppSelector} from "../../../../app/hooks";
import {selectUser} from "../../userSlice";
import {Translate} from "../../../intl/translator";

interface Inputs {
    city: string,
    postCode: string,
    street: string,
    streetNo: string,
    building: string,
    floor: string,
    flat: string,
    comments: string
}

interface Props {
    address: UserAddressI
}

export function EditAddress({address}: Props) {
    const strings = {
        's_title': Translate('add_address_step_3_title'),
        's_save': Translate('save'),
        's_post_code': Translate('post_code'),
        's_street_name': Translate('street_name'),
        's_city': Translate('city'),
        's_street_no': Translate('street_number'),
        's_building_name': Translate('building_name'),
        's_floor': Translate('floor'),
        's_flat': Translate('flat_number'),
        's_address_notes': Translate('address_notes'),
    }
    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}, setValue} = useForm<Inputs>();
    const user = useAppSelector(selectUser);

    const onPreviousClick = () => {
        navigate('/address/list',)
    }

    useEffect(() => {
        setValue('city', address.District);
        setValue('postCode', address.PostalCode);
        setValue('street', address.RoadName);
        setValue('streetNo', address.Number);
        setValue('building', address.BuildingName ?? "");
        setValue('floor', address.FloorNumber ?? "");
        setValue('flat', address.FlatNo ?? "");
        setValue('comments', address.Details ?? "");
    }, [address, setValue])

    const onSubmit: SubmitHandler<Inputs> = data => {
        const userAddress: UserAddressI = {
            LocationID: address.LocationID,
            RoadName: address.RoadName,
            RegisterUserID: user.RegisteredUserID,
            PostalCode: address.PostalCode,
            Municipality: address.Municipality,
            District: address.District,
            Number: data.streetNo,
            FlatNo: encodeURIComponent(data.flat) ?? "",
            FloorNumber: encodeURIComponent(data.floor) ?? "",
            BuildingName: encodeURIComponent(data.building) ?? "",
            Details: encodeURIComponent(data.comments) ?? ""
        }
        if (user.RegisteredUserID !== CONFIG.GUEST_USER_ID) {
            userEditAddress(userAddress).then(res => {
                navigate('/address/list')
            }).catch(e => {
                console.log(e)
            })
        }
    };

    return (
        <div className="bg-with-gradient flex flex-col">
            <StyledTheme.SizedContentPage>
                <div className="actions indent  py-8">
                    <div className="flex items-center">
                        <div className="flex items-center cursor-pointer" onClick={onPreviousClick}>
                            <ActionButton>
                                <BsChevronLeft size={24}/>
                            </ActionButton>
                            <div className="ml-4">CANCEL</div>
                        </div>

                    </div>
                </div>
            </StyledTheme.SizedContentPage>
            <Footer negative="-60vh">
                <>
                    <div>
                        <VendorLogo placement={VendorLogoPosition.Card}/>
                    </div>
                    <styled.AddAddress className="pt-36 px-8 pb-12 md:px-6 card-panel">
                        <div className='step-3'>
                            <h1>Add new address</h1>
                            <div className={'mb-3'}>
                                <label className={'text-gray-400'}>{strings.s_city} <span>*</span></label>
                                <AppInputField
                                    className={'small'}
                                    border={'solid thin var(--theme-sky-blue)'}
                                    {...register("city", {
                                        required: true,
                                        disabled: true,
                                    })}/>
                                {errors.city && <span className="text-red-600 text-sm">This field is required.</span>}
                            </div>
                            <div className={'mb-3'}>
                                <label className={'text-gray-400'}>{strings.s_post_code} <span>*</span></label>
                                <AppInputField
                                    border={'solid thin var(--theme-sky-blue)'}
                                    className={'small'} {...register("postCode", {
                                    required: true,
                                    disabled: true,
                                })}/>
                                {errors.postCode && <span className="text-red-600 text-sm">This field is required.</span>}
                            </div>
                            <div className={'mb-3'}>
                                <label className={'text-gray-400'}>{strings.s_street_name} <span>*</span></label>
                                <AppInputField
                                    border={'solid thin var(--theme-sky-blue)'}
                                    className={'small'} {...register("street", {
                                    required: true,
                                    disabled: true,
                                })}/>
                                {errors.street && <span className="text-red-600 text-sm">This field is required.</span>}
                            </div>
                            <div className={'mb-3'}>
                                <label className={'text-gray-400'}>{strings.s_street_no} <span>*</span></label>
                                <AppInputField
                                    border={'solid thin var(--theme-sky-blue)'}
                                    className={'small'} {...register("streetNo", {
                                    required: true,
                                })}/>
                                {errors.streetNo && <span className="text-red-600 text-sm">This field is required.</span>}
                            </div>
                            <div className={'mb-3'}>
                                <label className={'text-gray-400'}>{strings.s_building_name}</label>
                                <AppInputField
                                    border={'solid thin var(--theme-sky-blue)'}
                                    className={'small'} {...register("building")}/>
                            </div>
                            <div className={'mb-3'}>
                                <label className={'text-gray-400'}>{strings.s_floor}</label>
                                <AppInputField
                                    border={'solid thin var(--theme-sky-blue)'}
                                    className={'small'} {...register("floor")}/>
                            </div>
                            <div className={'mb-3'}>
                                <label className={'text-gray-400'}>{strings.s_flat}</label>
                                <AppInputField
                                    border={'solid thin var(--theme-sky-blue)'}
                                    className={'small'} {...register("flat")}/>
                            </div>
                            <div className={'mb-3'}>
                                <label className={'text-gray-400'}>{strings.s_address_notes}</label>
                                <textarea
                                    className="block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-clip-padding border border-solid border-sky-500 rounded transition ease-in-out mt-1 card-modded-bg focus:text-gray-700 focus:bg-white focus:border-black focus:outline-none"
                                    rows={4}
                                    {...register("comments")}>

                </textarea>
                            </div>
                            <FormButton className="mt-6" background={'var(--theme-sky-blue)'}
                                        onClick={handleSubmit(onSubmit)}>
                                {strings.s_save}
                            </FormButton>

                        </div>
                    </styled.AddAddress>
                </>
            </Footer>


        </div>
    );
}
