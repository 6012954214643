import axios from "axios";
import {CompanyUserI, PostalAddressI, UserAddressI, UserI} from "./userInterfaces";
import CONFIG from "../../app/config";
import {MenuItem} from "../menu/MenuItem";
import {VendorI} from "../vendor/vendorInterfaces";
import {CartI, NinaOrderI} from "../cart/cartInterfaces";
import {MenuItemI} from "../menu/menuInterfaces";
import {CartItem} from "../cart/CartItem";
import {PastOrderI} from "../cart/ordersSlice";

export function userLogin(phone: string, phoneCode: string) {
    return new Promise<UserI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/user/login?phone=${phone}&phoneCode=${phoneCode}`)
            .then(res => {
                if(res.status === 200) {
                    const user :UserI = res.data;
                    resolve(user);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userSendOtp(phone: string, phoneCode: string) {
    let pCode = encodeURIComponent(phoneCode);
    return new Promise<string>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/user/otp/send?phone=${phone}&phoneCode=${pCode}`)
            .then(res => {
                if(res.status === 200) {
                    const otp: string = res.data;
                    resolve(otp);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userVerifyOtp(phone: string, otp: string) {
    return new Promise<UserI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/user/otp/verify?phone=${phone}&otp=${otp}`)
            .then(res => {
                if(res.status === 200) {
                    const user :UserI = res.data;
                    resolve(user);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userUpdateData(user: UserI) {
    return new Promise((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/user/update`,{...user})
            .then(res => {
                if(res.status === 200) {
                    const user :UserI = res.data;
                    resolve(user);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userFavouriteProducts(userId: string, outletId: string) {
    return new Promise<Array<MenuItem>>((resolve, reject) => {
        if(userId === "0"){
            resolve([]);
        }
        axios.get(`${CONFIG.API_URL}/user/favourite/products?userId=${userId}&outletId=${outletId}`)
            .then(res => {
                if(res.status === 200) {
                    const result :Array<MenuItem> = res.data;
                    resolve(result);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userFavouriteProductToggle(userId: string, itemId: string) {
    return new Promise<boolean>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/user/favourite/product/toggle`, {userId, itemId})
            .then(res => {
                resolve(true)
            }).catch(e => reject(e))
    })
}

export function userFavouriteOutlets(userId: string) {
    return new Promise<Array<VendorI>>((resolve, reject) => {
        if(userId === "0"){
            resolve([]);
        }
        axios.get(`${CONFIG.API_URL}/user/favourite/outlets?userId=${userId}`)
            .then(res => {
                if(res.status === 200) {
                    const result :Array<VendorI> = res.data;
                    resolve(result);
                }else{
                    reject(res.status)
                }
            })
            .catch(e => reject(e))
    })
}

export function userFavouriteOutletToggle(userId: string, outletId: string) {
    return new Promise<boolean>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/user/favourite/outlet/toggle`, {userId, outletId})
            .then(res => {
                resolve(true)
            }).catch(e => reject(e))
    })
}

export function userCompletedOrders(userId: string, outletId: string) {
    return new Promise<Array<NinaOrderI>>((resolve,reject) => {
        axios.get(`${CONFIG.API_URL}/user/orders?userId=${userId}&outletId=${outletId}`)
            .then(res => {
                const orders: Array<NinaOrderI> = res.data;
                resolve(orders);
            }).catch(e => reject(e))
    })
}

export function userAddAddress(data: UserAddressI) {
    return new Promise((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/user/address/add`, data)
            .then(res => {
                resolve(res)
            }).catch(e => reject(e))
    })
}

export function userEditAddress(data: UserAddressI) {
    return new Promise((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/user/address/update`, data)
            .then(res => {
                resolve(res)
            }).catch(e => reject(e))
    })
}

export function listUserAddresses(userId: string, outletId: string) {
    return new Promise<Array<UserAddressI>>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/user/address/list?userId=${userId}&outletId=${outletId}`)
            .then(res => {
                const addresses: Array<UserAddressI> = res.data;
                addresses.forEach((address) => {
                    // console.log("FlatNo:", address.FlatNo);
                    // console.log("FlatNo:", decodeURIComponent(address.FlatNo ?? ""));
                    // console.log("FloorNumber:", address.FloorNumber);
                    // console.log("FloorNumber:", decodeURIComponent(address.FloorNumber ?? ""));
                    // console.log("BuildingName:", address.BuildingName);
                    // console.log("BuildingName:", decodeURIComponent(address.BuildingName?? ""));
                    // console.log("NickName:", address.NickName);
                    // console.log("NickName:", decodeURIComponent(address.NickName?? ""));
                    // console.log("RoadName:", address.RoadName);
                    // console.log("RoadName:", decodeURIComponent(address.RoadName?? ""));
                    // console.log("-----");
                    // You could also perform other actions here, such as updating state or calling functions
                  });
                resolve(addresses)
            }).catch(e => reject(e))
    })
}

export function postalAddresses(postal: string) {
    return new Promise<Array<PostalAddressI>>((resolve,reject) => {
        axios.get(`${CONFIG.API_URL}/delivery/postal/addresses?PostalCode=${postal}`)
            .then(res => {
                const addresses: Array<PostalAddressI> = res.data;
                resolve(addresses);
            }).catch(e => reject(e))
    })
}

export function deleteUserAddress(userId: string, locationId: string) {
    return new Promise((resolve,reject) => {
        axios.delete(`${CONFIG.API_URL}/user/address?userId=${userId}&locationId=${locationId}`)
            .then(res => {
                resolve(res);
            }).catch(e => reject(e))
    })
}

export function getCompanyUser(userId: string, outletId: string, companyId: string){
    return new Promise<CompanyUserI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/user/company?userId=${userId}&outletId=${outletId}&companyId=${companyId}`)
            .then(res => {
                const companyUser: CompanyUserI = res.data;
                resolve(companyUser);
            }).catch(e => reject(e))
    })
}

export function prepareUserLastCart(
    order: NinaOrderI,
    menuItemsList: Array<MenuItemI>
) {
    let cart: CartI = {items: []};
    order.Orderitems.forEach(orderItem => {
        let productIndex = menuItemsList.findIndex((menuItem) => menuItem.MenuItemID === orderItem.MenuItemID);
        if(productIndex >= 0){
            let product = menuItemsList[productIndex]
            let cartItem = new CartItem({
                Product: product,
                Qty: parseInt(orderItem.Quantity),
                Notes: orderItem.Notes,
            })
            orderItem.nameWithVariation.forEach(orderItemVariation => {
                //find the product variation group of the order item variation
                let productVariationGroup = product.Variations.find(productVariation => {
                    return productVariation.ItemVariationID === orderItemVariation.VariationID
                })
                if(productVariationGroup){
                    //find the productVariationGroup option
                    let productVariationOption = productVariationGroup.OptionList.find(productVariationOption => {
                        return productVariationOption.OptionID === orderItemVariation.OptionID
                    })
                    if(!productVariationOption) return;
                    // check if is single/radio or multiple
                    if(productVariationGroup.Multiple !== "0"){
                        for (let i = 0; i < orderItemVariation.Quantity; i++){
                            cartItem.addVariationQtyToGroup({
                                group: productVariationGroup, option: productVariationOption})
                        }
                    }else{
                        cartItem.toggleVariationSingleToGroup({
                            group: productVariationGroup, option: productVariationOption})
                    }
                }
            })
            cart.items.push(cartItem)
        }
    })
    let pastOrder: PastOrderI = {
        VendorId: order.OutletID,
        OrderId: order.OrderID,
        Cart: cart
    }
    return pastOrder;
}
