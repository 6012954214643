import {NinaOrderI, OrderItemI} from "./cartInterfaces";
import {CartI, CartItemI} from "./cartInterfaces";
import { CartItem } from "./CartItem";
export class NinaOrder{
    info: NinaOrderI;
    orderItems: Array<OrderItemI> = [];

    constructor(json: NinaOrderI) {
        this.info = json;
    }

    calcCartItemsTotal():number {
        let total = 0;
        
        this.info.Orderitems.map(item => {
            if(item) {
               
              //  console.log("price: "+item.Price);
              //  console.log("total: "+item.TotalItemPriceWithVariation)

                total += parseInt(item.TotalItemPriceWithVariation);
            }
        })
        return total;
    }

}
