import React, {useEffect, useState} from "react";
import {AiOutlineClose, AiOutlineLoading3Quarters} from "react-icons/ai";
import {Translate} from "../../../intl/translator";
import {OrderStatusScreenItem} from "./OrderStatusScreenItem";
import {NinaOrder} from "../../NinaOrder";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {loadOrder} from "../../cartRepository";
import {loadOrderPerPaymentRequestId} from "../../cartRepository";
import {v4 as uuid} from "uuid";
import {selectLocale} from "../../../intl/intlSlice";
import {addOrderToPastOrders, PastOrderI, selectLastOrder} from "../../ordersSlice";
import {clearCart} from "../../cartSlice";
import {useParams} from "react-router-dom";
import {getPaymentIconById, getPaymentTypeById} from "../../cartService";
import {getTableDetails} from "../../../vendor/vendorRepository";
import * as StyledTheme from "../../../theme/styled-components/Theme.styled";
import {Footer} from "../../../theme/copmponents/Footer";
import {VendorLogoPosition} from "../../../vendor/components/VendorLogo/VendorLogo.styled";
import {VendorLogo} from "../../../vendor/components/VendorLogo/VendorLogo";
import * as styled from "./OrderStatusScreen.styled";
import {selectUserAddress} from "../../../user/userSlice";
import {CartType} from "../../cartInterfaces";
import {selectVendorPaymentMethods} from "../../../vendor/vendorSlice";
import {toast} from "react-toastify";
import {selectVendor} from "../../../vendor/vendorSlice";
import {selectCart, selectCartType, setDeliveryCost} from "../../cartSlice";
import {useNavigate} from "react-router-dom";
import BlueButton from './BlueButton';
import { Cart } from "akar-icons";
import { userInfo, version } from "os";

export function OrderStatusScreen() {
    const strings = {
        s_order: Translate('order'),
        s_order_status_outlet_title: Translate('order_status_outlet_title'),
        s_order_status_date_title: Translate('order_status_date_title'),
        s_order_status_orderId_title: Translate('order_status_orderId_title'),
        s_order_status_table_title: Translate('order_status_table_title'),
        s_order_status_items_title: Translate('order_status_items_title'),
        s_order_status_comments_title: Translate('order_status_comments_title'),
        s_order_status_total_title: Translate('order_status_total_title'),
        s_order_status_payment_title: Translate('order_status_payment_title'),
        s_order_status_address: Translate('order_status_address'),
        s_orderMsg: Translate('orderMsg'),
        s_orderDefualt: Translate('orderDefault'),
    };
    const navigate = useNavigate();
    const [statusOrderMsg, setStatusOrderMsg] = useState(strings.s_orderDefualt);
    const [itemsTotal, setitemsTotal] = useState(0);

    const initOrder = {
        Status: "new",
        OrderID: "0",
        OutletID: "0",
        AmountTotal: "0",
        Orderitems: []
    }

    const handleClick = () => {
        // alert('Blue button clicked!');
        navigate('/load')
      };
      
    const vendor = useAppSelector(selectVendor);
    const deliveryTextWithLineBreaks = vendor.DeliveryText?  vendor.DeliveryText.replace(/\n/g, '<br>') : " ";
    const locale = useAppSelector(selectLocale);
    const lastOrder = useAppSelector(selectLastOrder);
    const dispatch = useAppDispatch();
    const address = useAppSelector(selectUserAddress);
    const paymentMethods = useAppSelector(selectVendorPaymentMethods);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(new NinaOrder(initOrder));
    const [tableNo, setTableNo] = useState("-")
    const {orderId, payId} = useParams();
    const [orderMsg, setOrderMsg] = useState("");
    const cart = useAppSelector(selectCart)
    const deliveryCharge = cart.deliveryCharge ?? 0;
    const deliveryTotal = parseFloat(order.info.DeliveryTotal ?? order.info.AmountTotal);
    const total = deliveryTotal ;
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
    };
    // const loadLastOrder = () => {
    //     if (orderId && payId) {
            
    //         if (lastOrder && lastOrder.PaymentRequestID === payId) {
    //             // lastOrder.OrderId = orderId;
    //             // let lastOrderToUpdate: PastOrderI = {...lastOrder};
    //             // lastOrderToUpdate.OrderId = orderId;
    //             loadOrderInfoNew(payId).then(res => {
                   
    //                 // dispatch(addOrderToPastOrders(lastOrderToUpdate));
    //             })
    //         }
    //     } else if (lastOrder && lastOrder.OrderId !== "0") {
    //         loadOrderInfo(lastOrder.OrderId).then(res => setLoading(false))
    //     }
    // }
    var itemsPrice = 0;
    const loadLastOrder = () => {
        if (orderId && payId) {
            if (orderId == payId){
                console.log("other payment")
                loadOrderInfoNew(payId).then(res => {
                                })
            }else if (lastOrder && lastOrder.PaymentRequestID === payId) {
                console.log("JCC")
                setStatusOrderMsg(strings.s_orderMsg);
                lastOrder.OrderId = orderId;
                let lastOrderToUpdate: PastOrderI = {...lastOrder};
                lastOrderToUpdate.OrderId = orderId;
                loadOrderInfo(orderId).then(res => {
                    setLoading(false)
                    dispatch(addOrderToPastOrders(lastOrderToUpdate));
                })
            }
        } else if (lastOrder && lastOrder.OrderId !== "0") {
            
           // console.log("cash here "+lastOrder.OrderId)
            loadOrderInfo(lastOrder.OrderId).then(res => setLoading(false))
        }
    }

    const loadOrderInfoNew = (ptypeId: string) => {
        return new Promise((resolve, reject) => {
            // console.log("try to get data for "+ptypeId)
            loadOrderPerPaymentRequestId(ptypeId, locale.LanguageID).then(res => {
                setOrder(new NinaOrder(res));
                
                // console.log("here "+res.OrderID)
                if (!res.OrderID){
                    loadOrderInfoNew(ptypeId).then(res => {
                   
                        // dispatch(addOrderToPastOrders(lastOrderToUpdate));
                    })
                }else{
                    // console.log("Order status: "+res.Status)
                    if (res.Status == "Completed"){
                        setStatusOrderMsg(strings.s_orderMsg);
                    }else{
                        setStatusOrderMsg(strings.s_orderDefualt);
                    }
                    console.log("msg: "+statusOrderMsg)
                    if(res.OrderMessage && res.OrderMessage !== orderMsg){
                        setOrderMsg(res.OrderMessage);
                    }
                    if (tableNo === "-") {
                        loadTableInfo(res.TableID ?? "0")
                    }
                    setLoading(false)
                }
                
                resolve(res);
            }).catch(e => reject(e))
        })
    }

    const loadOrderInfo = (orderId: string) => {
        return new Promise((resolve, reject) => {
            loadOrder(orderId, locale.LanguageID).then(res => {
                setOrder(new NinaOrder(res));
                setStatusOrderMsg(strings.s_orderMsg);
                if(res.OrderMessage && res.OrderMessage !== orderMsg){
                    setOrderMsg(res.OrderMessage);
                }
                if (tableNo === "-") {
                    loadTableInfo(res.TableID ?? "0")
                }
                resolve(res);
            }).catch(e => reject(e))
        })
    }

    const loadTableInfo = (tableId: string) => {
        getTableDetails(tableId).then(table => {
            setTableNo(table.TableNo)
        }).catch((e) => setTableNo("0"))
    }

    useEffect(() => {
        dispatch(clearCart());
        loadLastOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const refreshOrder = setInterval(() => {
            loadLastOrder();
        }, 100000)
        if (['Completed', "Canceled"].includes(order.info.Status)) {
            clearInterval(refreshOrder);
        }
        return () => {
            clearInterval(refreshOrder);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    useEffect(() => {
        if(orderMsg.length > 0){
            toast(orderMsg, {
                containerId: 'order-status',
                position: "bottom-center",
                autoClose: false,
                hideProgressBar: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                type: "success",
            });
        }
    },[orderMsg])

    const CloseNotificationButton = ({closeToast}: any) => (
        <AiOutlineClose onClick={closeToast} color="white" />
    )

    return (
        <div className="bg-with-gradient flex flex-col">
            <StyledTheme.SizedContentPage>
                {/*<div style={{height: "200px"}}></div>*/}
                {/*<OrderScreenHeader color={getOrderStatusColor(order.info.Status)}>

                    {!loading &&
                        <h2 className="text-center">{strings.s_order} <br/>
                            {order.info.Status}!
                        </h2>
                    }

                </OrderScreenHeader>*/}
            </StyledTheme.SizedContentPage>
            <Footer>
                <>
                    <div>
                        <VendorLogo placement={VendorLogoPosition.Card}/>
                    </div>

                    <div className='card-panel'>
                        <styled.OrderStatusScreen className="pt-36 px-8 pb-12 md:px-20">
                        <h2 style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px' }} >{statusOrderMsg}</h2>
                        <br></br>
                        <h2 style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: deliveryTextWithLineBreaks}}>
                    
                    </h2>
                    <br/>
                            <styled.OrderScreenDetails>
                                <div className="order">
                                    {!loading &&
                                        <>
                                            <div className="pb-4">
                                                <h6>{strings.s_order_status_date_title}</h6>
                                                <p className="text-lg">{order.info.Date?.slice(0, -3)}</p>
                                            </div>
                                            <div className="pb-4">
                                                <p style={{color: "grey"}}>{strings.s_order_status_orderId_title}</p>
                                                <p className="text-lg">{order.info.OrderID}</p>
                                            </div>

                                        </>
                                    }
                                </div>
                                <div className="address">
                                    {!loading && address && order.info.OrderType === CartType.Delivery.toString() &&
                                        <div className="pb-4">
                                            <p className={'pb-2'} style={{color: "grey"}}>{strings.s_order_status_address}</p>
                                            <p>{address.District}, {address.PostalCode}</p>
                                            <p>{address.RoadName} {address.Number}</p>
                                            <p>{address.BuildingName}</p>
                                            {address.FloorNumber &&
                                                <p>Floor {address.FloorNumber} #{address.FlatNo}</p>
                                            }
                                        </div>
                                    }
                                </div>
                            </styled.OrderScreenDetails>
                            <hr className="mb-4"/>
                            <div className="pb-2">
                                <p style={{color: "grey"}}>{strings.s_order_status_items_title}</p>
                            </div>
                            {loading &&
                                <div>
                                    <AiOutlineLoading3Quarters size={50} className="animate-spin m-auto" color="var(--theme-primary-color)"/>
                                </div>
                            }
                            {!loading &&
                                order.info.Orderitems.map(item => {
                                     
                                    
                                    
                                    let price: number = parseFloat(item.Price) * parseInt(item.Quantity);
                                    item.nameWithVariation.forEach(variation => {
                                        price += (parseFloat(variation.price ?? 0) * variation.Quantity) * parseInt(item.Quantity);
                                    })
                                    
                                    
                                    itemsPrice = itemsPrice + (price);
                                    
                                    // setitemsTotal(itemsPrice);
                                   // console.log("here item: "+ itemsPrice);
                                    return <OrderStatusScreenItem key={uuid()} item={item} />
                                })
                            }
                            {!loading &&
                                <div className="pb-2">
                                    {order.info.CustomerGeneralNote !== "-" &&
                                        <div className="mb-4">
                                            <p className="" style={{color: "var(--theme-primary-color)"}}>
                                                {strings.s_order_status_comments_title}:
                                            </p>
                                            <p>{order.info.CustomerGeneralNote}</p>
                                        </div>
                                    }

                                        <div className="flex place-content-between items-center content-center mb-2">
                                            <div>
                                                <h3 className="inline-block pr-2">
                                                   Items Total: 
                                                </h3>
                                            </div>
                                            <h2>&euro;{itemsPrice.toFixed(2).toString()}
                                            </h2>
                                        </div>

                                    {order.info.DeliveryCost && order.info.DeliveryCost !== "0" &&
                                        <div className="flex place-content-between items-center content-center mb-2">
                                            <div>
                                                <h3 className="inline-block pr-2">
                                                    Delivery:
                                                </h3>
                                            </div>
                                            <h2>&euro;{parseFloat(order.info.DeliveryCost ?? "0").toFixed(2).toString()}
                                            </h2>
                                        </div>
                                    }

                                    {order.info.DeliveryFee && order.info.DeliveryFee !== "0" &&
                                        <div className="flex place-content-between items-center content-center mb-2">
                                            <div>
                                                <h3 className="inline-block pr-2">
                                                 Minimum Order Fee:
                                                </h3>
                                            </div>
                                            <h2>&euro;{parseFloat(order.info.DeliveryFee ?? "0").toFixed(2).toString()}
                                            </h2>
                                        </div>
                                    }

                                    


                                    { cart.deliveryCharge &&
                                    <div className="flex place-content-between items-center content-center">
                                        <div>
                                            <h3 className="inline-block pr-2">
                                                Delivery Fee: 
                                            {/* {strings.s_order_status_total_title}: */}
                                            </h3>
                                        </div>
                                        <h2>&euro;{cart.deliveryCharge.toFixed(2).toString()}
                                        </h2>
                                    </div>
                                    }



                                    <div className="flex place-content-between items-center content-center">
                                        <div>
                                            <h3 className="inline-block pr-2">
                                            {strings.s_order_status_total_title}:
                                            </h3>
                                        </div>
                                        {/* order.info.DeliveryTotal ?? order.info.AmountTotal) + cart.deliveryCharge */}
                                        
                                        <h2>&euro;  {total.toFixed(2)}
                                        </h2>
                                    </div>

                                    {/*  {vendor.DeliveryMinCost} -  {cart.deliveryCharge} */}
                         
                                    

                                    <hr className="my-3"/>
                                    <div className="flex place-content-between items-center content-center">
                                        <div>
                                            <h3 className="inline-block pr-2">{strings.s_order_status_payment_title}:</h3>
                                        </div>
                                        <div className="payment-logo flex justify-center items-center">
                                            {getPaymentTypeById(paymentMethods, order.info.PaymentTypeID ?? "5")?.PaymentTypeName}
                                            <img width={49} src={getPaymentIconById(order.info.PaymentTypeID ?? "5")} alt=""/>
                                        </div>
                                        
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        marginTop: '20px'
                                    }}>
                                        
                                        <BlueButton onClick={handleClick}>Return To Home</BlueButton>
                                    </div>
    
                                </div>
                            }
                        </styled.OrderStatusScreen>
                    </div>

                </>

            </Footer>
            <styled.NotificationToast enableMultiContainer containerId={'order-status'} closeButton={CloseNotificationButton}  />
        </div>
    )
}
