const CONFIG = {
    //simplex
//    API_URL: "https://bridge.ninatech.co/api/v1",
//     MENU_IMAGES_URL:"https://admin.ninacy.com/NinaWeb-war/resources/images/menuItemsImages/",
//     IMAGES_URL: "https://admin.ninacy.com/NinaWeb-war/resources/images/outletGalleriesImages/",
//     LOGOS_URL: "https://admin.ninacy.com/NinaWeb-war/resources/images/outletsLogos/",
//     COVERS_URL: "https://admin.ninacy.com/NinaWeb-war/resources/images/outletsCover/",
//     MENU_ITEM_GALLERY_URL: "https://admin.ninacy.com/NinaWeb-war/resources/images/menuItemGalleriesImages/",
//     ALLERGEN_IMAGES_URL: "https://admin.ninacy.com/NinaWeb-war/resources/images/footAllergensImages/",
//     SPLASH_IMAGES_URL: "https://admin.ninacy.com/NinaWeb-war/resources/images/outletsSplashScreen/",

    // Azure 
    API_URL: "https://bridge.ninatech.co/api/v1",
    MENU_IMAGES_URL:"https://ninastimages.blob.core.windows.net/images/menuItemsImages/",
    IMAGES_URL: "https://ninastimages.blob.core.windows.net/images/outletGalleriesImages/",
    LOGOS_URL: "https://ninastimages.blob.core.windows.net/images/outletsLogos/",
    COVERS_URL: "https://ninastimages.blob.core.windows.net/images/outletsCover/",
    MENU_ITEM_GALLERY_URL: "https://ninastimages.blob.core.windows.net/images/menuItemGalleriesImages/",
    ALLERGEN_IMAGES_URL: "https://ninastimages.blob.core.windows.net/images/footAllergensImages/",
    SPLASH_IMAGES_URL: "https://ninastimages.blob.core.windows.net/images/outletsSplashScreen/",


    
    SESSION_DURATION: process.env.NODE_ENV === "production" ? 900000 : 9000000000,
    GUEST_USER_ID: "113",
    ENABLE_CART: true, //enable/disable cart functionality
    ENABLE_CALLS: false, // enable/disable outlet calls functionality
    ENABLE_USERS: true, // enable/disable users
    ENABLE_FAVOURITES: true, // enable user favourites
    ENABLE_GUEST_CHECKOUT: true,
    ENABLE_FOREGROUND_NOTIFICATIONS_USER_IDS: ["1683","1726", "434"],
    ENABLE_FOREGROUND_NOTIFICATIONS: true,
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyDHWHNifE_RhLqeF_rz9_VLMUSPNpwJBWo",
        authDomain: "nina-16496.firebaseapp.com",
        databaseURL: "https://nina-16496.firebaseio.com",
        projectId: "nina-16496",
        storageBucket: "nina-16496.appspot.com",
        messagingSenderId: "780010675477",
        appId: "1:780010675477:web:cbeb3091c65b3c58e7dd1c",
        measurementId: "G-C1V8D5BYZ4"
    }
};
export default CONFIG;