import {useEffect, useState} from "react";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {Navigate, useParams} from "react-router-dom";
import Cookies from "universal-cookie";
import {EstablishmentI} from "../../vendor/vendorInterfaces";
import {getQrOutlets} from "../../vendor/vendorRepository";

interface MultiVendorStatusI {
    type: "single" | "multi" | "none"
}

export function Load() {

    const [error, setError] = useState(false);
    const [preloaded, setPreloaded ] = useState(false);
    const [loaded, setLoaded ] = useState(false);
    const [multiVendorStatus, setMultiVendorStatus] = useState<MultiVendorStatusI>({type: "none"})
    const cookies = new Cookies();
    // const refStore = localStorage.getItem('refid');
    let {urlId} = useParams();
    let refId: string | undefined;

    





    if(cookies.get('refid')){
        refId = cookies.get('refid');
        localStorage.setItem('qr_ref', refId+"");
     //   console.log('ref cookie: '+ refId);
        
    }else{
        refId = urlId;
      //  console.log('ref no cookie: '+ refId);
    }
    

    if (!refId){
        refId = localStorage.getItem('qr_ref') ?? "";
       // console.log('ref in store: '+ refId);
    }
   
    
    useEffect(() => {
        if(refId){
            preloadApp(refId)
                .then(res => {
                    if(res.Outlets.length === 1 ){
                        setMultiVendorStatus({type: "single"})
                        localStorage.setItem('load', JSON.stringify({
                            id: res.Outlets[0].OutletID,
                            table: res.TableID
                        }))
                        
                        cookies.remove('refid');
                        setLoaded(true);
                    }
                }).catch(() => setError(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[refId])

    const preloadApp = (id: string) => {
        return new Promise<EstablishmentI>((resolve, reject) => {
            getQrOutlets(id).then(outlets => {
                setPreloaded(true);
                resolve(outlets)
            }).catch(e => reject(e));
        })
    }

    return (
        <>
            {!preloaded && !error &&
                <div className="flex items-center justify-center h-screen">
                    <AiOutlineLoading3Quarters size={50} className="animate-spin" color="var(--theme-primary-color)"/>
                </div>
            }
            {error &&
                <div className="flex items-center justify-center h-screen flex-col">
                    <AiOutlineLoading3Quarters size={50} className="animate-spin" color="var(--theme-primary-color)"/>
                    <br/>
                    <div>error loading...</div>
                </div>
            }
            {loaded && !error && multiVendorStatus.type === "single" && <Navigate replace to={"/splash"}/>}
        </>
    )
}
