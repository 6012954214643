import styled from "styled-components";

const DeliveryTypeScreen = styled.div`
  h1{
    text-align: center;
    font-size: 1.5rem;
    max-width: 600px;
    margin:  0 auto 30px;
    @media(max-width: 600px){
      font-size: 1rem;
    }
    
  }
  .options{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    .card-panel{
      min-height: 200px;
      background: white;
      border: solid;
      border-width: 0.5px;
      .image{
        padding: 30px;
        height: 250px;
        img{
          margin: auto;
        }
        @media(max-width: 600px){
          padding: 30px;
          height: auto;
        }
      }
     
      .title{
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
        h3{
          font-size: 20px;
          margin-right: 15px;
          font-weight: 400;
        }
      }
      &:hover{
        cursor: pointer;
        h3{
          color: var(--theme-sky-blue);
        }
        svg{
          fill: var(--theme-sky-blue);
        }
      }
    }
    @media(max-width: 600px){
     
    }
  }
  
`;

export {DeliveryTypeScreen}
