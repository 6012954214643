import axios from "axios";
import CONFIG from "../../app/config";
import {Order} from "./Order";
import {NinaOrderI} from "./cartInterfaces";

export interface CreateOrderResponseI{
    OrderID: string,
    Status: string
}

export interface CreatePaymentRequestOrderResponseI{
    PaymentRequestID: string,
    Status: string
}
export function createOrder(order: Order, locale: string) {
    return new Promise<CreateOrderResponseI>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/cart/order`, {
            order: order.formattedOrder(),
            deliveryCost: order.cart.deliveryCharge?.toString() ?? "0",
            deliveryFee: order.minOrderFee.toString() ?? "0",
            locale
        })
            .then(res => {
                const response: CreateOrderResponseI = res.data[0];
                resolve(response);
            })
            .catch(e => reject(e))
    })
}

export function createPaymentRequestOrder(order: Order, locale: string) {
    return new Promise<CreatePaymentRequestOrderResponseI>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/payments/request`, {
            order: order.formattedOrder(),
            locale
        })
            .then(res => {
                const response: CreatePaymentRequestOrderResponseI = res.data;
                resolve(response);
            })
            .catch(e => console.log(e))
    })
}

export function lastOrder(userId: string, outletId: string) {
    return new Promise<NinaOrderI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/cart/order/last?userId=${userId}&outletId=${outletId}`)
            .then(res => {
                resolve(res.data[0])
            })
            .catch(e => console.log(e))
    })
}

export function loadOrder(orderId: string, localeId: string) {
    return new Promise<NinaOrderI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/cart/order?orderId=${orderId}&localeId=${localeId}`)
            .then(res => {
                resolve(res.data[0])
            })
            .catch(e => console.log(e))
    })
}

export function loadOrderPerPaymentRequestId(prId: string, localeId: string) {
    return new Promise<NinaOrderI>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/cart/order/payments?ptypeid=${prId}&localeId=${localeId}`)
            .then(res => {
                // console.log("data here "+res.data[0].OrderID)
                resolve(res.data[0])
            })
            .catch(e => console.log(e))
    })
}


export function jccRequest(orderId: string, orderTotal: string, jccAccountId: string){
    return new Promise<string>((resolve, reject) => {
        axios.post(`${CONFIG.API_URL}/payments/jcc/request`, {
            orderId,
            orderTotal,
            jccAccountId,
        })
            .then(res => {
                const form: string = res.data;
                resolve(form);
            })
            .catch(e => console.log(e))
    })
}

export function getDeliveryCost(outletId: string, locationId: string) {
    return new Promise<number>((resolve, reject) => {
        axios.get(`${CONFIG.API_URL}/delivery/charge?outletId=${outletId}&locationId=${locationId}`)
            .then(res => {
                resolve(parseFloat(res.data.ExtraCost))
            })
            .catch(e => console.log(e))
    })
}
