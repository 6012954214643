import {MenuItem} from "../menu/MenuItem";
import {MenuItemVariationGroupI, MenuItemVariationOptionI} from "../menu/menuInterfaces";
import {VendorPaymentMethodI} from "../vendor/vendorInterfaces";
import {Order} from "./Order";
import {AnyAction, Dispatch} from "@reduxjs/toolkit";
import {NavigateFunction} from "react-router-dom";
import {LocaleInterface} from "../intl/intlSlice";
import {Vendor} from "../vendor/Vendor";
import {UserI} from "../user/userInterfaces";

export enum CartType{
    'None',
    'Order',
    'TakeAway',
    'Delivery',
    'Waiter'
}

export interface cartItemVariationGroupI {
    group: MenuItemVariationGroupI,
    items: Array<MenuItemVariationOptionI>
}

export interface CartItemI {
    Id?: string;
    Product: MenuItem;
    Qty?: number;
    Variations?: Array<cartItemVariationGroupI>;
    Notes?: string;
    GroupValidationErrors?: Array<CartItemGroupValidationError>
}

export interface CartI {
    items: Array<CartItemI>,
    notes?: string,
    type?: CartType,
    deliveryCharge? : number
    deliveryMinOrder? : number
}
export interface OrderItemVariationI {
    QuantityLimit: string,
    ShowQuantity: string,
    Quantity: string,
    CustomID: string,
    MenuItemID: string,
    OptionID: string,
    SelectionName: string,
    TAG: string,
    VariationID: string
    hasDefaultPrice: string,
    optionName: string,
    price: string,
    variationName: string
}

export interface OrderItemI {
    AllDayClosed: string,
    Calories: string,
    CategoryName: string,
    CustomID: string,
    DefaultPreparationTime: string,
    Description: string,
    DisplayCalories: string,
    DisplayMenu: string,
    FileName: string,
    ItemName: string,
    ItemVariationID: string,
    MenuItemID: string,
    Notes: string,
    Price: string,
    PrinterID: string,
    Quantity: string,
    Recommended: string,
    TAG: string,
    TagItem: string,
    TagName: string,
    TotalItemPriceWithVariation: string,
    itemNum: number,
    itemOrderList: number,
    PromoID: string,
    nameWithVariation: Array<OrderItemVariationI>,
    selectedVariations: Array<OrderItemVariationI>

  
}

export interface OrderI {
    Reguserid: string,
    Ptypeid: number,
    Customergeneralnotes: string,
    OrderType: CartType,
    Scantimestamp: string,
    Tableid: number,
    Outletid: number,
    Roomnum: string,
    Houseaccount: string,
    Orderitems: Array<OrderItemI>,
    FirebaseToken?: string,
    Locationid?: string
}


export interface NinaOrderI {
    OrderID: string,
    RegisteredUserID?: string,
    TableID?: string,
    OutletID: string,
    VoidReasonID?: string,
    PaymentTypeID?: string,
    RoomNum?: string,
    AmountTotal: string,
    Status: string,
    PaidCompleted?: string,
    OrderCompleted?: string,
    IsDelayed?: string,
    BackofficePrint?: string,
    IsDeletedByUser?: string,
    EstimatedPreparationTime?: string,
    ActualPreparationTime?: string,
    RequireAttendance?: string,
    Void?: string,
    VoidText?: string,
    CustomerGeneralNote?: string,
    EmployeeGeneralNote?: string,
    Date?: string,
    FirebaseID?: string,
    RefUniqueNo?: string,
    AlohaOrderID?: string,
    OrderType?: string,
    TableStatus?: string,
    ScanTimeStamp?: string,
    Name?: string,
    Symbol?: string,
    FileName?: string,
    Details?: string,
    Orderitems: Array<NinaOrderItemI>,
    DeliveryCost?: string,
    DeliveryFee?: string,
    DeliveryTotal?: string,
    OrderMessage?: string,
    LocationID?:string;
}

export interface NinaOrderItemI {
    AllDayClosed: string,
    Calories: string,
    CategoryName: string,
    CustomID: string,
    DefaultPreparationTime: string,
    Description: string,
    DisplayCalories: string,
    DisplayMenu: string,
    ItemName: string,
    ItemVariationID: string,
    MenuItemID: string,
    Notes: string,
    Price: string,
    PrinterID: string,
    Quantity: string,
    Recommended: string,
    TAG: string,
    TagItem: string,
    TagName: string,
    TotalItemPriceWithVariation: string,
    itemNum: number,
    itemOrderList: number,
    nameWithVariation: Array<NinaOrderItemVariationI>
}

export interface NinaOrderItemVariationI {
    CustomID: string
    MenuItemID: string
    OptionID: string
    Quantity: number
    QuantityLimit: string
    SelectionName: string
    ShowQuantity: string
    VariationID: string
    hasDefaultPrice: string
    optionName: string
    price: string
    variationName: string
}

export interface CartItemGroupValidationError{
    group: MenuItemVariationGroupI,
    msg: string
}

export interface PaymentDataI {
    orderId: string,
    orderTotal: string,
    accountId: string
}

export enum PaymentTypes {
    "PAYPAL"= "1",
    "JCC"= "2",
    "JCC_API"= "28",
    "VIVA_WALLET"= "4",
    "CASH" = "5",
    "ROOM_CHARGE" = "6",
    "APPLE_PAY" = "7",
    "CREDIT_CARD" = "8",
    "SAFER_PAY" = "9",
    "WALLEE" = "10",
    "HOUSE_ACCOUNT" = "11"
}

export interface GatewayDataI {
    paymentType : VendorPaymentMethodI,
    order: Order,
    navigate: NavigateFunction,
    activeLocale: LocaleInterface,
    dispatch: Dispatch<AnyAction>,
    vendor: Vendor,
    cart: CartI,
    user: UserI
}
